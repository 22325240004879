import React from 'react'
import { DownpageLink } from '../data/downpage-link';
import "../App.css"

type Props = {
    item : DownpageLink
}

const SingleLink = ({item}:Props) => {
  return (
    <div><span className="name"><a href={item.href} target={item.target}>{item.label}</a></span></div>
  )
}

export default SingleLink