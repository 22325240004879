import React from 'react';
import './App.css';
import AuthenticatedDownpage from './pages/AuthenticatedDownpage';

function App() {
  return (
    <AuthenticatedDownpage/>
  );
}

export default App;
