export interface DownpageLink {
    id: number;
    href: string;
    target?: string;
    label: string;
}

export const appLinkList: DownpageLink[] = [
{ id: 1, href: "https://access.sentara.com/", target: "_blank", label: "Applications - Workspace ONE" },
{ id: 2, href: "https://outlook.office.com/mail", target: "_blank", label: "eMail" },
{ id: 3, href: "https://sentara.service-now.com/sentara", target: "_blank", label: "IT Self Service Catalog" },
{ id: 4, href: "https://sentara.service-now.com/$pwd_reset.do?sysparm_url=sentara_pwd_reset", target: "_blank", label: "Self Service Password Reset" },
{ id: 5, href: "https://sentara.service-now.com/", target: "_blank", label: "ServiceNow Fulfiller" },
{ id: 6, href: "https://www.myworkday.com/sentara/login-saml2.htmld", target: "_blank", label: "Workday" },                      
];
