import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

export const ReactStrictMode = <React.StrictMode>
  <App />
</React.StrictMode>

export const root = document.getElementById('root')

ReactDOM.render(
  ReactStrictMode,
  root
);
