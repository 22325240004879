import wavenetLogo from "../images/wavenet-logo.png";
import { appLinkList } from "../data/downpage-link";
import SingleLink from "../components/SingleLink";
import "../App.css"

const AuthenticatedDownpage = () => {
  return (
      <>
        <div className="header">
          <div className="container">
            <div className="row">
              <a className="navbar-brand" href="/">
                <img
                  width="125"
                  height="51"
                  src={wavenetLogo}
                  alt="sentara logo"
                />
                <div className="WN-BannerTextDiv">
                  We improve health every day.
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="downMsg">
          WaveNet is currently not available. Below is a list of applications
          that are available at this time:
        </div>
        <div id="appsList">
          <div className="Columns">
            <div className="WS1column">
              <div id="CatBusFin" className="cat">
                <div id="apps">
                  <div className="list">
                    {appLinkList.map((item) => (
                      <SingleLink 
                      key={item.id} 
                      item={item} />
                    ))}
                  </div>
                  <br />
                  <p className="linkInfo">
                      If you can't launch your application in Workspace ONE,
                      contact the{" "}
                      <a
                        href="https://sentara.service-now.com/sentara"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <u>IT Help Desk</u>
                      </a>{" "}
                      and ask for your Incident to be routed to the Workspace
                      ONE Application Owner.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

export default AuthenticatedDownpage;
